import { Directive, HostListener, ElementRef } from '@angular/core';
// import { FormControl } from '@angular/forms';
import { format } from 'rut.js';

@Directive({
  selector: '[formatRut]'
})
export class FormatRutDirective {

  constructor( private _elementRef: ElementRef ) {}

  @HostListener('input', ['$event.target.value']) onChange(typedValue: string) {
    if (typedValue) {
      const rut = format(typedValue);
      this._elementRef.nativeElement.value = rut;
    }

  }

}
