import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-modal-bienvenida',
  templateUrl: './modal-bienvenida.component.html',
  styleUrls: ['./modal-bienvenida.component.scss'],
})
export class ModalBienvenidaComponent implements OnInit {

  msg: string = 'Podrás consultar información como datos personales, cuentas, saldos, movimientos y transacciones. También podrás enviar certificados, cartolas y generar claves de acceso provisorias.';

  constructor(
    public modal: ModalController,

  ) { }

  ngOnInit() {}

  close() {
    this.modal.dismiss();
  }

}
